function request(_this, url, query) {
	_this.$api[url]({
		data: query
	}).then(({
		result
	}) => {
		if (result) {
			if (_this.init) _this.init();
			else if (_this.refresh) _this.refresh()
			else _this.$refresh()
		}
	})
}
export default {
	methods: {
		getTabBarImgSrc(path){
			// return new URL('../../'+path,import.meta.url).href //部署不可用
			const modules = import.meta.globEager("/static/tabrBar/**/*.png");
			return modules[path].default;
		},
		//获取static/images图片
		getImgSrc(path){
			// return new URL('../../'+path,import.meta.url).href //部署不可用
			const modules = import.meta.globEager("/static/images/*.png");
			return modules[`/static/images/${path}`].default;
		},
		$clearField(data) {
					if (data.constructor !== Object) throw new Error('parameterm must be a object!!!');
					for (let key in data) {
						if (Object.hasOwnProperty.call(data, key)) {
							if (typeof data[key] === 'number') data[key] = undefined;
							else if (typeof data[key] === 'string') data[key] = '';
							else if (data[key] instanceof Array) data[key] = [];
							else if (data[key] instanceof Object) data[key] = {};
						}
					}
					if(this.$refs.fileRef) this.$refs.fileRef.clear();
				},
		// 公共请求 用于直接接口
		$request(url, query, prompt) {
			if (prompt) this.$showModal({
				content: prompt,
				icon: 'warning'
			}).then(confirm => {
				if (confirm && url) request(this, url, query);
			})
			else if (url) request(this, url, query);
		},
	}
}
