import { createStore } from 'vuex'
import env from '@env';
// const getEnvVariable=(key)=>process.env.NODE_ENV === 'development'?(env[key] || ''):''
const getEnvVariable=(key)=>env[key] || ''
export default createStore({
    state: {
		currentModule:getEnvVariable('currentModule'),
        entryPath: 'home', //缓存刚进入页面时的路径，用于登录后再次跳转
		entryFullPath:'',//刚进入网站的地址
        token: getEnvVariable('token'),
        scrollTop: 0, //长列表组件滚动距离
        cache: {title:{},isAuthHandle:false },
		loading:0,
		showLoading:true
    },
    mutations: {
		setLoading(state,flag){
			state.showLoading=flag;
		},
		increaseLoading(state){
			state.loading++;
		},
		subtractLoading(state){
			if(state.loading>0) state.loading--;
		},
		resetLoading(state){
			state.loading=0;
		},
		setModule(state, module) {
		    state.currentModule = module;
		},
        // 将入口地址存入，登录后跳向
        setEnteryPath(state, path) {
            state.entryPath = path;
        },
		setEnteryFullPath(state, path) {
		    state.entryFullPath = path;
		},
        //   存入token 用户检测用户态
        setToken(state, token) {
            state.token = token;
        },
        setScrollTop(state, scrollTop) {
            state.scrollTop = scrollTop;
        },
        setCache(state, set) {
            state.cache = Object.assign({}, state.cache, set);
        },
        clearCache(state, key) {
            if (typeof key === 'string') {
                state.cache[key] = null;
            } else if (typeof key === 'object') {
                key.forEach(item => state.cache[item] = null);
            } else {
                state.cache = {};
            }
        }
    }
});