import eventBus from '@/eventBus.js';
import store from '@/store/index.js';
import {
	isInExclude
} from '@utils/auth.js';
export default {
	onLoad(e) {
		// this.$on('forceFetch', () => {
		// 	// console.log(this.$refs.recycle)
		// 	if (this.$refs.recycle) this.$refs.recycle.forceFetch();
		// });
		// 清除请求拦截的缓存
		store.commit('clearCache', 'apiCache');
		this.query = e || {};
		if(!this.$route) this.$route={
			query:e
		}
		this.isFirstIn = true;
		// #ifdef H5
		// 避免跳转新路由停留在上个页面位置
		window.scroll(0,0)
		// #endif
	},
	onShow() {
		// this.isVLog = false;
		// if (((this.$query && this.$query.title) || this.title) && !this.isVLog) this.addVLog(this.title ||
		// 	this.$query.title);
		// this.$eventBus.onSingle('setTitle', title => {
		// 	if (!this.isVLog) this.addVLog(title)
		// })
		this.isFirstIn = false;
	},
	onBackPress(e) {
		eventBus.emit('page-back', e);
	},
	methods: {
		isValid(value) {
			return value !== null &&value!==''&& value !== undefined&&!Number.isNaN(value);
		},
		$refresh() {
			if (this.$refs.recycle) this.$refs.recycle.refresh();
		},
		// Property or method "toJSON" is not defined on the instance but referenced during render. Make sure that this property is reactive, either in the data option, or for class-based components, by initializing the property. See: https://vuejs.org/v2/guide/reactivity.html#Declaring-Reactive-Properties.
		toJSON() {},
		/* 查询节点信息
		 * 目前此方法在支付宝小程序中无法获取组件跟接点的尺寸，为支付宝的bug(2020-07-21)
		 * 解决办法为在组件根部再套一个没有任何作用的view元素
		 * @param all 是否查询所有元素
		 * @return
		 * {bottom dataset height:number id left right top width:number}
		 **/
		$query(selector, all = false) {
			return new Promise(resolve => {
				const query = uni.createSelectorQuery().in(this)[all ? 'selectAll' : 'select'](
					selector);
				query.boundingClientRect(rect => {
					if (all && Array.isArray(rect) && rect.length) {
						resolve(rect)
					}
					if (!all && rect) {
						resolve(rect)
					}
				}).exec();
			})
		},
		//可获取route 获取当前页面路由   options 获取路由参数
		$getPage() {
			const pages = getCurrentPages();
			return pages[pages.length - 1] || {};
		},
		addVLog(title) {
			this.isVLog = true;
			// 必须每个页面设置
			const route = this.$getPage().route;
			if (process.env.NODE_ENV !== 'development' && !isInExclude(route)) this.addViewLog(this.title ||
				title, route).then(({
				title_home
			}) => {
				// #ifdef H5
				uni.setNavigationBarTitle({
					title: title_home || '煤矿信息平台'
				});
				if (this.share) {
					this.wxShare({
						title: typeof this.share.title === 'function' ? this.share.title(
							title_home) : this.share.title
					});
				}
				// #endif
			})
		},
		addViewLog(title, path) {
			return base.addUserViewLog({
				loading: false,
				data: {
					title,
					path
				}
			})
		},
		// 获取父组件的参数，因为支付宝小程序不支持provide/inject的写法
		// this.$parent在非H5中，可以准确获取到父组件，但是在H5中，需要多次this.$parent.$parent.xxx
		// 这里默认值等于undefined有它的含义，因为最顶层元素(组件)的$parent就是undefined，意味着不传name
		// 值(默认为undefined)，就是查找最顶层的$parent
		$getParent(name = undefined) {
			let parent = this.$parent;
			// 通过while历遍，这里主要是为了H5需要多层解析的问题
			while (parent) {
				// 父组件
				if (parent.$options && parent.$options.name !== name) {
					// 如果组件的name不相等，继续上一级寻找
					parent = parent.$parent;
				} else {
					return parent;
				}
			}
			return false;
		}
	}
};
