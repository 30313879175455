<template>
	<view class="u-loading-wrap">
		<view class="u-loading-shadow" v-if="loading">
			<view v-show="loading" class="u-loading-dot" :class="mode === 'circle' ? 'u-loading-circle' : 'u-loading-flower'" :style="style"></view>
		</view>
		<view class="u-loading-content" :class="[loading?'blur':'']"><slot v-if="isFirstLoad?!loading:true"></slot></view>
	</view>
</template>

<script>
/**
 * loading 加载动画
 * @description 警此组件为一个小动画，目前用在uView的loadmore加载更多和switch开关等组件的正在加载状态场景。
 * @property {String} mode 模式选择，见官网说明（默认circle）
 * @property {String} color 动画活动区域的颜色，只对 mode = flower 模式有效（默认#c7c7c7）
 * @property {String Number} size 加载图标的大小，单位rpx（默认34）
 * @property {Boolean} loading 是否显示动画（默认true）
 * @example <u-loading mode="circle"></u-loading>
 */
import addUnit from '../../lib/function/addUnit.js';
export default {
	name: 'u-loading',
	props: {
		// 动画的类型
		mode: {
			type: String,
			default: 'circle'
		},
		// 动画的颜色
		color: {
			type: String,
			default: '#1668dc'
		},
		// 加载图标的大小，单位rpx
		size: {
			type: [String, Number],
			default: '34'
		},
		// 是否显示动画
		loading: {
			type: Boolean,
			default: true
		},
		loadingStyle: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		style() {
			const style = {};
			style.width = addUnit(this.size);
			style.height = addUnit(this.size);
			// 占1/4的弧
			if (this.mode === 'circle') style.borderColor = `#e4e4e4 #e4e4e4 #e4e4e4 ${this.color}`;
			return Object.assign({}, style, this.loadingStyle);
		}
	},
	watch:{
		/**
		 * 由于slot内可能初次加载没有数据的内容，为避免初次加载因为字段为空报错，且之后reload能在基本的骨架上loading
		 * 因此初次loading设置标记
		 * */
		loading:{
			handler(val){
				if(!val&&this.isFirstLoad) this.isFirstLoad=false;
			},
			immediate:true
		}
	},
	data(){
		return {
			isFirstLoad:true
		}
	}
};
</script>

<style lang="less">
	.u-loading{
		
		&-circle {
			border-radius: 50%;
			border: 2px solid;
			animation: u-circle 1s linear infinite;
		}
		&-content {
			height: 100%;
			overflow: auto;
		}
		&-flower {
			animation: u-flower 1s steps(12) infinite;
			background: transparent
				url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgxMDB2MTAwSDB6Ii8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTlFOUU5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTMwKSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iIzk4OTY5NyIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgzMCAxMDUuOTggNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjOUI5OTlBIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDYwIDc1Ljk4IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0EzQTFBMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSg5MCA2NSA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNBQkE5QUEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwIDU4LjY2IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0IyQjJCMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgxNTAgNTQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjQkFCOEI5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDE4MCA1MCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDMkMwQzEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTE1MCA0NS45OCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDQkNCQ0IiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTEyMCA0MS4zNCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNEMkQyRDIiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDM1IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0RBREFEQSIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgtNjAgMjQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTJFMkUyIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKC0zMCAtNS45OCA2NSkiLz48L3N2Zz4=)
				no-repeat;
			background-size: 100%;
		}
	}
.u-loading-wrap {
	position: relative;
	// width: 100%;
	// height: 100%;
	min-width: 30px;
}
.u-loading-content.blur{
	clear: both;
	    opacity: 0.5;
	    user-select: none;
	    pointer-events: none;
}
.u-loading-shadow {
	margin: 0 auto;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	display: block;
	width: 100%;
	height: 100%;
	max-height: 400px;
	.u-loading-dot{
		    position: absolute;
		    top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
	}
}
@keyframes u-flower {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

@keyframes u-circle {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
