<template>
	<u-popup mode="bottom" v-model="showPop">
	<view class="live-video">
		<video class="my-video" :controls='false' :show-center-play-btn='false' object-fit='cover' :class="['my-video-'+id]" ref="videoRef" src=""></video>
		<cover-view class="btns">
			<view><u-iconfont icon="icon-zuojiantou" color="#1980FF" size='80rpx' @click='close'></u-iconfont></view>
			<view><u-iconfont icon="icon-duigou" color="#00BF70" size='80rpx' @click='snapshot'></u-iconfont></view>
		</cover-view>
	</view>
	</u-popup>
</template>

<script>
	import guid from '@utils/guid';
import uface from '@widget/uface';
import {ref,onMounted,getCurrentInstance} from 'vue';
export default {
	name: 'LiveVideo',
	props:{
		success:{
			type:Function,
			default:()=>()=>{}
		},
		fail:{
			type:Function,
			default:()=>()=>{}
		},
		onClose:{
			type:Function,
			default:()=>()=>{}
		},
	},
	setup(props) {
		const id=guid(12)
		const internalInstance = getCurrentInstance()
		console.log(internalInstance)
		const showPop=ref(false);
		const videoRef=ref(null);
		const show=()=>showPop.value=true;
		const close=()=>{
			if(!showPop.value) return false;
			showPop.value=false;
			props.onClose()
		};
		let liveVideo;
		const start=() =>{
				const video = document.querySelector('.my-video-'+id).querySelector('video');
				console.log('ajajaj',video.clientWidth)
				liveVideo =  new uface.LiveVideo({videoEl:video});
				liveVideo.start()
				// .then(stream => {
				// 	liveVideo.startVideo(video, stream);
				// });
			}
		onMounted(()=>{
			show();
			setTimeout(()=>{
				start()
			},20)
			
		})
		return {
			id,
			showPop,
			videoRef,
			close,
			stop() {
				liveVideo.stop();
			},
			/**
			 * const img = document.createElement('img');
				img.src = base64;
				img.style.width = '300px';
				img.style.height = '300px';
				document.body.appendChild(img);
			 * */
			snapshot() {
				const res = liveVideo.snapshot();
				props.success(res);
				close();
			}
		};
	}
};
</script>

<style lang="less" scoped>
	.live-video{
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		// background: linear-gradient(0deg, #0092EF, #1FA8FF);
		.my-video{
			// width: 460rpx;
			// height: 600rpx;
			// border: 10rpx solid #DCF4FF;
			// border-radius: 5px;
			width: 100vw;
			height: 100vh;
			position: fixed;
			left: 0;
			top: 0;
		}
		.btns{
			display: flex;
			justify-content: center;
			margin-top: 100rpx;
			position: fixed;
			bottom: 100rpx;
			left: 0;
			right: 0;
			view{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 119rpx;
				height: 119rpx;
				background: #fff;
				border-radius: 50%;
				border: 10rpx solid #BEEAFF;
				&:last-child{
					margin-left: 120rpx;
				}
			}
		}
	}
</style>
