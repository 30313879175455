  /**
     * `[showLoading description]`  加载中
     * @method showLoading
     * '@date 2018-08-21'
     * '@author zkq'
     * @param  {String}    [title='加载中']     [description]
     * @param  {Boolean}   [mask=true]       [description]
     * @return {[type]}                      [description]
     */
 function   showLoading({title = '加载中', mask = true}={}) {
        return new Promise(
            (resolve, reject) => {
                uni.showLoading({
                    title,
                    mask,
                    success: resolve,
                    fail: reject,
                });
            }
        );
    }
    export default showLoading;
