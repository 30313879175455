<template>
	<u-empty v-if="data&&data.length<=0" description="暂无附件"></u-empty>
	<view v-if="data&&data.length>0" class="media">
		<u-img v-for="(img, index) in data" style="margin-right: 20rpx;margin-bottom: 20rpx;" width="100rpx"
			height="80rpx" :preview="true" :preview-list='imgPreviewList' :key="index" :src="img.full_path"></u-img>

	</view>
</template>

<script>
	export default {
		name: 'Media',
		props: {
			data: {
				type: Array,
				default: () => []
			},
		},
		computed: {
			imgPreviewList() {
				if (this.data) return this.data.map(d => d.full_path)
				return [];
			}
		}
	};
</script>

<style scoped lang="less">
	.media {}
</style>
