<template>
	<view>
		<view class="tab-bar" :style="{ color: tabBar.color, 'background-color': tabBar.backgroundColor }">
			<view class="item" v-for="(item, index) in tabBar.list" :key="index" :class="currentIndex === index ? 'on'+index : ''" @tap="switchTab(index, item.pagePath)">
				<!-- <image mode="aspectFit" class="img" :src="currentIndex === index ? getTabBarImgSrc(item.selectedIconPath) : getTabBarImgSrc(item.iconPath)"></image> -->
				<u-iconfont :icon="currentIndex === index ?item.selectedIconfont:item.iconfont" size="50" :color="currentIndex === index ?tabBar.selectedColor:tabBar.color"></u-iconfont>
				<view class="text" :style="[currentIndex === index ? { color: tabBar.selectedColor } : { color: tabBar.color }]">{{ item.text }}</view>
			</view>
		</view>
		<view class="space"></view>
	</view>
</template>

<script>
import {getTabBar} from '@config';
export default {
	name:'TabBar',
	props:{
		current:{
			type:[Number,String],
			default:0
		}
	},
	data() {
		const tabBar=getTabBar();
		return {
			tabBar,
			currentIndex: 0
		};
	},
	watch:{
		current:{
			immediate:true,
			handler(val){
				this.currentIndex=Number(val);
			}
		}
	},
	mounted() {
		const pages=getCurrentPages(),curPage=pages[pages.length-1];
		// this.currentIndex=this.tabBar.list.findIndex(item=>this.$route.fullPath.includes(item.pagePath))
		this.currentIndex=this.tabBar.list.findIndex(item=>('/'+curPage.route).includes(item.pagePath))
		console.log('index',this.currentIndex)
	},
	methods: {
		switchTab(index, url) {
			index=Number(index)
			if (index === this.currentIndex) return false;
			else this.jump(index,url)
		},
		jump(index, path) {
			// this.currentIndex = index;
			this.$jump({ path,mode:'redirect' });
		}
	}
};
</script>

<style lang="less">
.tab-bar {
	height: @tab-bar;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	border-top: 1rpx solid @border-color;
	.item {
		flex-grow: 1;
		padding: 10rpx 0;
		text-align: center;
		.img {
			width: 38rpx;
			height: 38rpx;
		}
		.text{
			font-size: 28rpx;
		}
	}
}
.space {
	height: @tab-bar;
}
</style>
