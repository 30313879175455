const isDev = process.env.NODE_ENV === 'development';
import dev from './.dev.js';
import devPro from './.dev-pro.js';
import pro from './.pro.js';
// export default isDev?dev:pro;
const env={
	dev,
	devPro,
	pro
};
console.log('env',process.env.NODE_ENV,process.env.APP_ENV)
export default process.env.NODE_ENV==='development'?dev:process.env.APP_ENV?env[process.env.APP_ENV]:pro;