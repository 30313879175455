// https://demo.dcloud.net.cn/push/unipush.HBuilder/index.php
// https://segmentfault.com/a/1190000020918807
// id":"unipush","token":"6e4709d2b90f58e1f35659ae8cb54c76","clientid":"6e4709d2b90f58e1f35659ae8cb54c76","appid":"pPyZWvH3Fa6PXba10aJ009","appkey":"b7dOGlNPHR7pqwUxDhpTi4"
// import {
// 	purefn,
//     uface
// } from 'widget';
// import login from 'api/login';
// import message from '@/apis/message.js';
// const {setReadSignByMsgId}=message;
import store from 'store';
function bindCid() {
    var info = plus.push.getClientInfo();
    console.log(info);
    login.addUserCid({
		check:false,
        data: {
            cid: info.clientid
        }
    })
}
// 标记消息已读
function setReaded(id){
	// uni.showModal({
	// 	content:'templateid:'+id
	// })
	setReadSignByMsgId({loading:false,check:false,judge:false,data:{id}})
}
function messagePush() {
    //#ifdef APP-PLUS  
	// plus.runtime.setBadgeNumber(0);
    var info = plus.push.getClientInfo();
    // console.log(info);
    // uni.showModal({
    // 	content:'cid:'+info.clientid
    // })
    // 监听在线消息事件
    plus.push.addEventListener("receive", function (msg) {
		// plus.runtime.setBadgeNumber(1)//添加角标
        // plus.ui.alert(2);  
        console.log('receive', msg)
        //这里可以写跳转业务代码
        console.log("recevice:", JSON.stringify(msg))
        // webchat.showModal({content:"recevice:"+ JSON.stringify(msg)})
        // webchat.jump({
        //     url: JSON.parse(msg.payload).urllink
        // })
    }, false);
    /* 5+  push 消息推送 ps:使用:H5+的方式监听，实现推送 离线消息*/
    plus.push.addEventListener("click", function (msg) {
		// plus.runtime.setBadgeNumber(1)//添加角标
        console.log(msg)
        console.log("click:" + JSON.stringify(msg));
        // webchat.showModal({title:'msg',content:"click:"+ JSON.stringify(msg)})
		// webchat.showModal({title:'payload',content:(typeof msg.payload)+' : '+(msg.payload)})
        //离线消息：string 在线:object
		const payload=typeof msg.payload==='string'? JSON.parse(msg.payload):msg.payload;
		store.commit('setEnteryPath',payload.urllink);//防止登录失效,缓存进入地址
		setReaded(purefn.StrUtil.queryString(payload.urllink).templateid);
        uface.jump({
        	url: payload.urllink
        })
    }, false);


    //#endif  
}
// http://www.mamicode.com/info-detail-2803468.html
function getLocation() {}

/**
 * 解析 query string 返回指定键的queryString
 * @param {*} str  query string
 * @param {*} name  键
 */
function parseQueryString(str, name, prefix) {
    const queryString = str.split('?')[1];
    if (!queryString) return '';
    const keyMap = queryString.split('&').reduce((accumulator, current) => {
        const [key, value] = current.split('=');
        accumulator[key] = value;
        return accumulator;
    }, {})
    if (name) return prefix ? prefix + keyMap[name] : keyMap[name];
    return keyMap;
}
function validateImage(src){
	return new Promise(resolve=>{
		const img=new Image();
		img.src=src;
		img.onload=function(){
			resolve(true)
		}
		img.onerror=function(){
			resolve(false)
		}
	})
}
function getFileFromBase64(base64URL, filename='file.png') {
        var arr = base64URL.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
export {
    messagePush,
    bindCid,
    parseQueryString,
	setReaded,
	validateImage,
	getFileFromBase64
};