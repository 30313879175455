<template>
	<!-- #ifdef H5 -->
	
		<svg class="u-icon" aria-hidden="true" :style='iconStyle'>
		    <use v-bind:[attribute]="'#'+icon"></use>
		</svg>
	<!-- #endif -->
	<!-- #ifdef APP-PLUS -->
	<text :class="['iconfont u-icon '+icon]" :style='iconStyle'></text>
	<!-- #endif -->
</template>

<script>
	import addUnit from '../../lib/function/addUnit.js';
	export default {
		name:'u-iconfont',
		props:{
			icon:{
				type:String
			},
			color:String,
			size:[Number,String]
		},
		computed:{
			attribute(){
				return 'xlink:href'
			},
			iconStyle(){
				const style={};
				if(this.color) style.color=this.color;
				if(this.size) style.fontSize= addUnit(this.size);
				return style
			}
		}
	}
</script>

<style scoped>
	  .u-icon {
	       width: 1em; height: 1em;
	       vertical-align: -0.15em;
	       fill: currentColor;
	       overflow: hidden;
	    }
</style>
