import {onMounted, ref} from 'vue';
import api from '@api';
import { isPromise,isInValid} from "@utils/is";
import purefn from '@widget/purefn'
import WxSDK from '@/utils/WxSDK.js';
import {shareConfig} from '@/config/index.js';
import { useStore } from 'vuex';
	
	
export function useState(initial){
	const state=ref(initial);
	return [state,newVal=>state.value=newVal]
}
/**
 * network request
 * manual 手动发起请求
 */
export function useRequest({
  request,
  config: param = {},
  manual = false,
  onSuccess = (e) => e,
} = {}) {
  let initialValue = { loading: true, data: [], isFinish: false };
  if (!request) initialValue = { loading: false, data: [], isFinish: true };
  const [loading, setLoading] = useState(initialValue.loading);
  const [data, setData] = useState(initialValue.data);
  const [isFinish, setIsFinish] = useState(initialValue.isFinish);
  let permanentRef = null;
  const reload = (query, { permanent = false } = {}) => {
    if (!request) return;
    if (permanent)
      permanentRef = Object.assign({}, permanentRef, query);
    setLoading(true);
    const onSuccessHandle = (res) => {
      setData(onSuccess(res));
      setTimeout(() => {
        setLoading(false);
        setIsFinish(true);
      }, 0);
    };
    const requestParam = {
      ...param,
      data: Object.assign(
        {},
        param.data || {},
        query,
        permanentRef || {}
      ),
    };
    if (typeof request === "string")
      api[request](requestParam).then(onSuccessHandle);
    else if (typeof request === "function")
      request(requestParam).then(onSuccessHandle);
    else if (isPromise(request)) request.then(onSuccessHandle);
  };
    onMounted(()=>{
		// return
		if (!manual) reload();
		else setLoading(false);
	})
  return {
    loading,
    reload,
    data,
    setData,
    isFinish,
  };
}

/**
 * 可编辑多项
 */
export function useEditableArray(d1=[],d2=[]){
	const [data,setData]=useState(d1)
	const [viewData,setViewData]=useState(d2)
	const addData=(e1,e2)=>{
		data.value.push(e1);
		viewData.value.push(e2)
	}
	const editData=(e1,e2,{index}={})=>{
		if(!isInValid(index)){
			data.value[index]=e1;
			viewData.value[index]=e2;
		}
	}
	const delData=({index}={})=>{
		if(!isInValid(index)){
			data.value.splice(index,1);
			viewData.value.splice(index,1)
		}
	}
	return {
		data,
		getData:({index}={})=>{
			if(!isInValid(index)){
				return purefn.clone(data.value[index]);
			}
			return purefn.clone(data.value)
		},
		viewData,
		addData,
		setData:(_1,_2)=>{
			data.value=_1;
			viewData.value=_2;
		},
		editData,
		delData
	}
}

export function useShare(configProps={},{
	manual=true
}={}){
	const store = useStore();
	const entryFullPath = store&&store.state.entryFullPath;
	const share=(config={})=>{
		const wxSDK = new WxSDK({ entryFullPath });
		wxSDK.share(Object.assign({},{
			title:shareConfig.title
		},config))
	}
	onMounted(()=>{
		if(!manual) return
		share(configProps)
	})
	return {share}
}