import store from '@/store/index.js';
import recycleView from '@/common/components/recycle-view/recycleView.js';
import {registerDirective} from '@/common/components/index.js';
import eventBus from '@/eventBus.js';
import MultiImage from '@/components/MultiImage.vue';
import TabBar from '@/components/TabBar.vue';
// import MultiFile from '@/components/MultiFile.vue';
import Media from '@/components/Media.vue';
import StatueBar from '@/common/components/nav-bar/StatusBar.vue';
import Footer from '@/components/Footer.vue';
import api from '@api';

import {
	uface,
	purefn
} from '@widget';
export default function(app) {
	// #ifdef H5
	window.onhashchange=e=>{
		console.log('hash-change')
		eventBus.emit('hashchange',e);
	}
	// #endif
	registerDirective(app);
	app.component(MultiImage.name,MultiImage);
	app.component(TabBar.name,TabBar);
	// app.component(Media.name,Media);
	app.component(StatueBar.name,StatueBar);
	app.component(Media.name,Media);
	app.component(Footer.name,Footer);
	app.use(store);
	app.config.globalProperties.$jump = uface.jump;
	app.config.globalProperties.$showModal = uface.showModal;
	app.config.globalProperties.$defineModal=uface.defineModal;
	app.config.globalProperties.$showToast = uface.showToast;
	app.config.globalProperties.$judgeif = purefn.judgeif;
	app.config.globalProperties.$eventBus = eventBus;
	app.config.globalProperties.$api=api;
	// 怀疑链 fn(this,'a.b.c')
	app.config.globalProperties.$proChain=(obj,field)=>{
		field.split('.').reduce((accu,cur)=>{
			return (accu===undefined||accu===null)?undefined:accu[cur]!==undefined&&accu[cur]!==null?accu[cur]:undefined;
		},obj);
	}
	/** 重取属性 fn(this,['a','b|c','!d','*'])
	* b|c 重命名属性
	* !d 排除d
	* * 通配符，所有属性会加到数组末尾，因此和!没有顺序关系
	*/
	app.config.globalProperties.$rePro=function (obj,fields){
		if(!obj||!(obj instanceof Object)) throw new Error('must be an object!!!');
		// 匹配*
		if(fields.includes('*')) {
			fields.splice(fields.indexOf('*'),1);
			Object.keys(obj).forEach(key=>{
				fields.push(key);
			})
		}
		const exclude=[];
			return fields.reduce((accu,cur)=>{
				if(/^!\w+/.test(cur)) exclude.push(cur.split('!')[1]);
				if(exclude.includes(cur.match(/!?(\w+)/)[1])) return accu;
				if(/\w+\|\w+/.test(cur)){
					const match=cur.match(/(\w+)\|(\w+)/);
					accu[match[2]]=obj[match[1]];
					return accu;
				}
				 accu[cur]=obj[cur];
				return accu;
			},{})
		}
	const mixins=import.meta.globEager('./mixins/*.js');
	Object.keys(mixins).forEach(name=>{
		app.mixin(mixins[name].default);
	})
}
