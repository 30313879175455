<template>
	<footer class="footer">
		<view>
			<view class="title">联系我们</view>
			<view class="texts">
				<view>公司：{{data.name}}</view>
				<view>电话：{{data.phone}}</view>
				<view>邮箱：{{data.email}}</view>
				<view>地址：{{data.address}}</view>
			</view>
		</view>
		<view class="account">
			<view class="title">关注公众号</view>
			<image class="qrcode" :src='data.qrcode_pub_full'></image>
		</view>
	</footer>
</template>

<script setup>
	import {
		useRequest
	} from '@hooks';
	import {
		defineComponent
	} from 'vue';

	const {
		data
	} = useRequest({
		request: 'Index/getPlatformInfo'
	})
</script>
<script>
	export default {
		name: 'Footer'
	}
</script>

<style lang="less" scoped>
	footer {
		padding: 42rpx 20rpx;
		background-color: @theme-color;
		color: #fff;
		display: flex;
		justify-content: space-between;

		.texts {
			view {
				margin-bottom: 20rpx;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.title {
			font-size: 34rpx;
			font-weight: 400;
			color: #FEFEFE;
			margin-bottom: 26rpx;
		}

		.account {
			.qrcode {
				width: 154rpx;
				height: 154rpx;
			}
		}
	}
</style>
