class strUtil {
	constructor(str) {
		this.str = str;
	}
	get value() {
		return this.str;
	}
	set value(value) {
		this.str = value;
	}
	get length() {
		return this.str.length;
	}

	/**
	 * 获取随机字符串
	 * @param len
	 * @returns {*}
	 */
	static radomString(len) {
		if (len > 32 || !len) len = 32;
		const chars =
			"ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
		let radomStr = "";
		for (let i = 0; i < len; i++) {
			radomStr += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return radomStr;
	}
	//判断字符是否为空的方法
	isEmpty(str) {
		if (typeof str == "undefined" || str == null || str == "") {
			return true;
		}
		return false;
	}
	/**
	 * [trim description]
	 * @method trim
	 * '@date 2018-08-13'
	 * '@author zkq'
	 * @param  {string}  str       [要处理的字符串]
	 * @param  {string} is_global [是否全局去空]
	 * @return {string}            [返回的结果 字符串]
	 */
	trim(is_global = false) {
		var result;
		result = this.str.replace(/(^\s+)|(\s+$)/g, "");
		if (is_global) {
			result = result.replace(/\s/g, "");
		}
		this.str = result;
		return this.str;
	}
	/**
	 * 解析query string
	 * fullMatch 开启全匹配，场景： http://localhost:8081/?#/advisoryManage/advisoryEdit?caseid=55&casedetailid=55&title=%E4%BF%AE%E6%94%B9%E5%92%A8%E8%AF%A2%E4%B8%AD
	 */
	static queryString(str, {
		fullMatch = false,
		exclude = []
	} = {}) {
		if (str.includes("?")) str = str.split("?")[1];
		if (fullMatch)
			return (str.match(/([^=&?]+)=([^=&?]+)/g) || []).reduce((accu, cur) => {
				if (exclude.includes(cur.split("=")[0])) return accu;
				if (!cur.split("=")[1]) return accu;
				accu[cur.split("=")[0]] = cur.split("=")[1];
				return accu;
			}, {});
		return str.split("&").reduce((accumulator, current) => {
			const result = current.split("=");
			if (exclude.includes(result[0])) return accumulator;
			if (!result[1]) return accumulator;
			accumulator[result[0]] = result[1];
			return accumulator;
		}, {});
	}
}
export default strUtil;