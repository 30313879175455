<template>
	<view class="my-class" @click="choose">
	    <slot />
	</view>
</template>

<script>
	export default {
		name:'u-choose-image',
	    props: {
	        limit:{
				type:Number,
				default:9
			},
	    },
	    methods: {
	        choose(){
	            const limit=this.limit,_this=this;
	            uni.chooseImage({
	                count: limit,
	                sizeType: ['original', 'compressed'],
	                sourceType: ['album', 'camera'],
	                success (res) {
						// console.log(res)
	                    // tempFilePath可以作为img标签的src属性显示图片
						//["blob:http://localhost:8080/6300fe30-0eec-4b62-a4ea-54c3bc35c7b2"]
	                    _this.$emit('change',res.tempFilePaths )
						/**
						 * tempFiles:[
							 * File:{
								 * name:"289545684193116351.jpg"
								 * size:345976
								 * type:"image/jpeg"
								 * path:"blob:http://localhost:8080/6300fe30-0eec-4b62-a4ea-54c3bc35c7b2"
							 }
						 ]
						 * */
						_this.$emit('files',res.tempFiles )
	                },
					fail(err){
						console.log(err);
					}
	            })
	        }
	    }
	};
	
</script>

<style>
</style>
