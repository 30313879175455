import typeUtil from './typeUtil';

const getType = typeUtil('getType');

function getReg(a) {
    var c = a.lastIndexOf('/');
    var reg = a.substring(1, c);
    var escMap = {
        '"': '\\"',
        '\\': '\\\\',
        '\\b': '\\b',
        '\\f': '\\f',
        '\\n': '\\n',
        '\\r': '\\r',
        '\\t': '\\t',
        '\\w': '\\w',
        '\\s': '\\s',
        '\\d': '\\d'
    };
    for (var i in escMap) {
        if (reg.indexOf(i)) {
            reg.replace(i, escMap[i]);
        }
    }
    var attr = a.substring(c + 1);
    return new RegExp(reg, attr);
}

function cloneType(value) {
    let temp;
    if (getType(value) === 'Function') {
		// 若funtion内引用了其他外部变量，则会导致undefined错误
        // temp = new Function("return " + value.toString())();
		temp = value;
    } else if (getType(value) === 'RegExp') {
        temp = getReg(value.toString());
    } else {
        temp = value;
    }
    return temp;
}

/**
 * 深拷贝
 * @param obj
 * @param map
 * @returns {{}|*}
 */
function clone(obj, map = new Map()) {
    let res;
    if (getType(obj) === 'Array') {
        res = [];
    } else if (getType(obj) === 'Object') {
        res = {}
    } else {
        return cloneType(obj);
    }
    // if (obj.page) {obj.pages=obj.page;
    //     delete obj.page;}
    // map.set(obj,obj);
    for (let item in obj) {
            if (obj[item] === obj) continue; //存在自身引用则跳出当前循环
            if (map.has(obj[item])) {
                res[item]=map.get(obj[item]);
            } else if (typeof obj[item] === 'object') {
                map.set(obj[item],obj[item]);
                res[item] = clone(obj[item], map);
            } else {
                res[item] = cloneType(obj[item]);
            }
    }
    return res;
}

export default clone;
