import getTabBar from './getTabBar.js';
// import modal from './modal.js';
const 	recycleView = { //长列表组件配置项
		initialPageNum: 0, //定义 页数计算的索引
		pageNumKey: 'page' //定义 请求后台页数的字段
	},
	pages = {
		// 排除登录控制的页
		exclude: []
	};
const colors = {
		themeColor: '#166EFF',
		tabs:{
			inactiveColor:'#8E8E8E'
		}
	},
	encryptKey = 'hr_token_key';
	const shareConfig={
		title:'太原嘉丰伟业科技有限公司'
	}
export {
	shareConfig,
	encryptKey,
	// modal,
	// coImg: {
	//     // prefix:'https://wxmkpt.tyjfwy.com/uploads/images'   //src前缀
	// },
	recycleView,
	pages,
	colors,
	getTabBar
};
