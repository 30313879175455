<template>
	<view class="u-mask" :class="{'u-mask-show':modelValue}" v-show="modelValue" :style="style" @touchmove.prevent @tap="click"><slot /></view>
</template>
<script>
	import PopupManager from '../utils/popup-manage.js';
/**
 * mask 遮罩
 * @description 创建一个遮罩层，用于强调特定的页面元素，并阻止用户对遮罩下层的内容进行操作，一般用于弹窗场景
 * click-able 是否点击关闭
 * @property {Boolean} show 是否显示遮罩（默认false）
 * @property {String Number} z-index z-index 层级（默认1070）
 * @property {Object} custom-style 自定义样式对象，见上方说明
 * @property {String Number} duration 动画时长，单位毫秒（默认300）
 * @property {Boolean} zoom 是否使用scale对遮罩进行缩放（默认true）
 * @property {Boolean} mask-click-able 遮罩是否可点击，为false时点击不会发送click事件（默认true）
 * @event {Function} click mask-click-able为true时，点击遮罩发送此事件
 * @example <u-mask :show="show" @click="show = false"></u-mask>
 */
export default {
	name: 'u-mask',
	emits:['update:modelValue','close'],
	props: {
		// 是否显示遮罩
		modelValue: {
			type: Boolean,
			default: false
		},
		// 层级z-index
		zIndex: {
			type: [Number, String],
			default: ''
		},
		// 是否可以通过点击遮罩进行关闭
		clickAble: {
			type: Boolean,
			default: true
		},
		// 是否使用背景色
		background: {
			type: Boolean,
			default: true
		},
		// 遮罩的动画样式， 是否使用使用zoom进行scale进行缩放
		zoom: {
			type: Boolean,
			default: true
		},
		// 遮罩的过渡时间，单位为ms
		duration: {
			type: [Number, String],
			default: 300
		}
	},
	computed: {
		style() {
			const style = {
				backgroundColor: this.background ?  'rgba(0, 0, 0, .6)':'',
				zIndex: this.modelValue? this.zIndex||PopupManager.nextZIndex():-1,
				transition : `all ${this.duration / 1000}s ease-in-out`
			};
			return style;
		}
	},
	methods: {
		click() {
			this.$emit('close');
			if (!this.clickAble) return;
			this.$emit('update:modelValue', false);
		}
	}
};
</script>
<style lang="less" scoped>
.u-mask {
	position: fixed;
			height: 100vh;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			transition: transform 0.3s;
			&-show{
				opacity: 1;
			}
}
</style>
