import simpleList from '@utils/request/index.js';
import store from '@/store';

/**
 * 模块输出 https://juejin.cn/post/6844904154574356493
 * 当模块变更后，切换api
 */
let currentModule = '';
const api={};
function buildapiContext(module) {
	if (module&&module == currentModule) return;
	currentModule = module;
	const apiContext =
		import.meta.globEager("./*.js"),
		apiModuleContext =
		import.meta.globEager("./*/*.js");
	Object.keys(apiContext).forEach(key => {
		const apiName = key.match(/\/(.*)\.js$/)[1];
		if (apiName === 'index') return
		api[apiName] = simpleList(apiContext[key].default);
	})
	Object.keys(apiModuleContext).forEach(key => {
		const [full,moduleName,apiName] = key.match(/\/(.*)\/(.*)\.js$/);
		if(module===moduleName) api[apiName] = simpleList(apiModuleContext[key].default);
	})
}


function getAPI(method, api) {
	method.split(/\W/).forEach(key => {
		api = api[key];
	});
	return api;
}
//     首字母小写
function initialLower(str) {
	const strF = str.slice(0, 1),
		strFLow = strF.toLocaleLowerCase()
	return (str.split(/\W/)[0] === 'index' ? str[0] : strFLow) + str.slice(1);
}
export default new Proxy(api, {
	get(target, key, receiver) {
		buildapiContext(store.state.currentModule)
		console.log(api.base)
		if (key.split(/\W/)[1]) {
			if (target[initialLower(key).split(/\W/)[0]] && getAPI(initialLower(key), target))
			return getAPI(initialLower(key), target);
			// else  return rest=> api.base.http(Object.assign({},{url:key},rest));
			else return simpleList(key);
		} else return Reflect.get(target, initialLower(key), receiver);
	}
});
