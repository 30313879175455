
	 import store from '@/store';
	  export default function getTabBar(){
		return {
  "color": "#444444",
  "selectedColor": "#1980ff",
  "backgroundColor": "#ffffff",
  "list": [
    {
      "pagePath": "/pages/home/index",
      "iconPath": "/static/tabrBar/txhg/tab-1.png",
      "iconfont": "icon-shouyefill",
      "selectedIconfont": "icon-shouyefill",
      "selectedIconPath": "/static/tabrBar/txhg/tab-1-active.png",
      "text": "首页"
    }
  ]
}
	  }