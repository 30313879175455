/**
 * @description 函数科里化, 适用于函数应用的高级抽象，利用闭包的特性保存函数的状态，避免使用全局变量保存状态。且应当及时销毁函数，避免闭包占用大量内存。
 * 用闭包保存住了已知参数。而命令式程式可能就需要使用变量暂存或其他方法来保证同时得到所有参数。
 * @param {*} fn 
 * @param  {...any} rest 
 */
// curry(fn, ...rest) {
//     return (...oRest) => {
//         return fn(...rest, ...oRest);
//     };
// }
const curry = (fn, arr = []) => (...args) => (
    arg => arg.length === fn.length ? fn(...arg) : curry(fn, arg)
)([...arr, ...args]);
export default curry;