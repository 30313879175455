import env from '@env';
import showToast from './uShowToast';
export default function({
	path='/pages/home/home',
	title='标题',
	desc='描述',
	image="/logo.png"
}={}){
	uni.share({
		provider: "weixin",
		scene: "WXSceneSession",
		type: 0,
		href: env.domain+path,
		imageUrl: image,
		title: title,
		summary: desc,
		success: function(res) {
			showToast({title:'分享成功',icon:'success'});
		},
		fail: function(err) {
			console.log("fail :" + JSON.stringify(err));
		}
	});
}