<template>
	<view class="u-empty">
		<image src="/static/common/empty.svg" mode="aspectFit"></image>
		<view class="u-empty-description">{{description}}</view>
	</view>
</template>

<script>
export default {
	name: 'u-empty',
	props:{
		description:{
			type:String,
			default:'暂无数据'
		}
	}
};
</script>

<style lang="less" scoped>
.u-empty {
	margin: 20rpx 0;
	opacity: 1;

	text-align: center;
	image {
		height: 40px;
		margin: auto;
	}
	&-description {
		color: rgba(0, 0, 0, 0.25);
		font-size: 14px;
		line-height: 1.5714285714285714;
		text-align: center;
	}
}
</style>
