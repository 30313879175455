/**
 * 全局拦截器
 */
export default class InterceptorManage{
    constructor(){
        this.handle=[];
    }
    // 添加拦截器 为防止任务顺序错乱，给reject添加默认值
    use(resolve,reject=err=>Promise.reject({err:err||'interceptor default error'})){
        this.handle.push({resolve,reject});
    }
    each(fn){
        this.handle.forEach(e=>{
            fn(e)
        });
    }
}