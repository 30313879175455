<template>
    <view>
        <view :style="{ height: statusBarHeight }" class="uni-status-bar">
            <slot />
        </view>
        <view :style="{ height: statusBarHeight }"></view>
    </view>
</template>

<script>
    var statusBarHeight = uni.getSystemInfoSync().statusBarHeight + 'px'
    export default {
        name: 'StatusBar',
        data() {
            return {
                statusBarHeight: statusBarHeight
            }
        }
    }
</script>

<style lang="less">
    .uni-status-bar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        width: 100%;
        height: 20px;
        background-color: #fff;
        height: var(--status-bar-height);
    }
</style>