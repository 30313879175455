import typeUtil from './typeUtil';
const getType=typeUtil('getType');
/**
 * 比较两个对象是否完全一样；具体思路如下： 

　　　　1.  先判断2个对象的数据类型是否一致。

　　　　2.  如果对象的数据是基础的数据类型; 直接比较; 如果是 Number, 对NaN进行特殊处理。

　　　　3.  如果对象的数据类型是 Array; 对象进行循环, 逐值进行判断。

　　　　4.  如果对象的数据类型是 Object; 分别对象的key, value 进行判断。

　　　　5.  如果对象的数据类型是 Map 或者 Set;  转化为Array进行判断。
*/
export default function deepEqual(source,compare){
	if(arguments.length<2) throw new Error('Incorrect number of parameters!!!');

	const sourceType=getType(source);
	if(sourceType!==getType(compare)) return false;
	// simple type
	if(sourceType!=="Array"&&sourceType!=="Object"&&sourceType!=="Set"&&sourceType!=="Map"){
		if(Number.isNaN(source)) return Number.isNaN(compare);
		if(sourceType==='Date'||sourceType==='RegExp') return source.toString()===compare.toString();
		return source===compare;
	}else if (sourceType==='Array') {
		if(source.length!==compare.length) return false;
		if(source.length===0) return true;
		for(let i=0;i<source.length;i++){
			if(!deepEqual(source[i],compare[i])) return false;
		}
	}else if(sourceType==='Object'){
		const sourceKeys=Object.keys(source),compareKeys=Object.keys(compare);
		if(sourceKeys.length!==compareKeys.length) return false;
		if(sourceKeys.length===0) return true;
		for (let i = 0; i < sourceKeys.length; i++) {
			const key=sourceKeys[i]
			if(key!==compareKeys[i]) return false;
			if(!deepEqual(source[key],compare[key])) return false;
		}
	}else if(sourceType==='Set'||sourceType==='Map'){
		// 转换为数组比较
		if(!deepEqual(Array.from(source),Array.from(compare))) return false;
	}
	return true;
}