// import '@/plugins/weixin-js-sdk';
import apiReq from '@api';
import {
	getImageFileFromUrl
} from '@utils/file';
import uface from '@widget/uface';
import browser from './browser';
const domain = location.origin;
const wx = window.jWeixin = jWeixin;
class WxSDK {
	constructor({
		entryFullPath = location.href
	} = {}) {

		this.entryFullPath = entryFullPath;
		// this.config()
	}
	/**
	 * ios签名失败 https://segmentfault.com/q/1010000002520634
	 * IOS版微信的自带浏览器在访问单页面应用时，当前路径会记录为首次打开的URL，而非实际的URL
	 */
	config({
		apis = [],
		shareUrl = browser().versions.ios ? this.entryFullPath : location.href.split('#')[0]
		// shareUrl = location.href.split('#')[0]
		// shareUrl =  this.entryFullPath 
		// shareUrl = `${config.domain}${this.$route.fullPath}`
	} = {}) {
		const api = [
			// 所有要调用的 API 都要加到这个列表中
			'checkJsApi',
			'chooseImage',
			'uploadImage',

			...apis
		];
		return new Promise((resolve, reject) => {
			apiReq.base.getSignPackage({
				loading: false,
				data: {
					jumpurl: shareUrl
				}
			}).then(signPackage => {
				wx.config({
					debug: false, // 开启调试模式
					appId: signPackage["appId"], // 必填，公众号的唯一标识
					timestamp: signPackage["timestamp"], // 必填，生成签名的时间戳
					nonceStr: signPackage["nonceStr"], // 必填，生成签名的随机串
					signature: signPackage["signature"], // 必填，签名，
					jsApiList: api // 必填，需要使用的JS接口列表
				});
				wx.ready(function(res) {
					// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
					resolve(res);
				});
				wx.error(function(error) {
					// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
					console.error(error)
					reject(error);
					return;
				});
			}).catch(err => {
				console.error(err)
			});
		});
	}
	getLocalImgData(localId) {
		return new Promise((resolve, reject) => {
			wx.uploadImage({
				localId, // 需要上传的图片的本地ID，由 chooseImage 接口获得
				isShowProgressTips: 1, // 默认为1，显示进度提示
				success: function(res) {
					const serverId = res.serverId; // 返回图片的服务器端ID
					resolve(serverId)
				},
				fail: reject,
				cancel: reject
			})
		})
	}
	chooseImage({
		count = 9,
		sizeType = ['original', 'compressed'],
		sourceType = ['album', 'camera']
	} = {}) {
		return new Promise((resolve, reject) => {
			this.config({apis:['uploadImage','downloadImage','getLocalImgData']}).then(() => {
			wx.chooseImage({
				count, // 默认9
				sizeType, // 可以指定是原图还是压缩图，默认二者都有
				sourceType, // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {
					const localIds = res
						.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
						console.log('localIds',localIds)
						uface.showLoading();
					// getImageFileFromUrl(localIds[0],'a.jpg').then(base64=>{
					// 	console.log(base64)
					// })
					// const image=document.createElement('img');
					// image.src=localIds[0];
					// image.style.width='100px'
					// image.style.height='100px'
					// document.body.querySelector('.user-pic').appendChild(image)
					// wx.getLocalImgData({localId:localIds[0],success(d){
					// 	console.log('local',res.localData)
					// }})
					Promise.all(localIds.map(e => this.getLocalImgData(e))).then(d => {
						console.log('serverID',d)
						uface.hideLoading();
						resolve(d)
					})
				},
				fail: reject,
				cancel: reject
			});
			})
		})
	}
	// 获取经纬度
	getLocation(type = 'wgs84') {
		return new Promise((resolve, reject) => {
			this.config({
				apis: ['getLocation']
			}).then(() => {
				wx.getLocation({
					type, // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
					success: function(res) {
						//  latitude; // 纬度，浮点数，范围为90 ~ -90
						//  longitude; // 经度，浮点数，范围为180 ~ -180。
						//  speed; // 速度，以米/每秒计
						//  accuracy; // 位置精度
						resolve(res)
					},
					fail: reject,
					cancel: reject
				});
			}).catch(err => {
				reject(err)
			})
		});
	}
	// 打开地图 使用火星坐标
	chooseLocation() {
		return new Promise((resolve, reject) => {
			this.config({
				apis: [
					'openLocation'
				]
			}).then(() => {
				this.getLocation('gcj02').then(location => {
					console.log(location)
					wx.openLocation({
						latitude: location.latitude, // 纬度，浮点数，范围为90 ~ -90
						longitude: location
							.longitude, // 经度，浮点数，范围为180 ~ -180。
						success: function(res) {
							resolve(res);
						},
						fail(err) {
							reject(err);
						}
					})
				});
			});
		});
	}
	// 微信分享
	share({
		title = '标题',
		desc = '点击查看更多',
		url = location.href,
		img = domain + "/static/images/logo.png"
	} = {}) {
		const link = url.startsWith('http')?url: `${domain}${url.startsWith('/')?'':'/'}${url}`;
		return new Promise((resolve, reject) => {
			/*微信端配置*/
			this.config({
				// shareUrl: link,
				apis: ['updateAppMessageShareData', 'onMenuShareAppMessage','updateTimelineShareData']
			}).then(function() {
				//自动执行的
				wx.checkJsApi({
					jsApiList: [
						'updateAppMessageShareData',
						'onMenuShareAppMessage',
						'updateTimelineShareData'
					],
					success: function() {},
					fail(err) {}
				});
				wx.updateAppMessageShareData({
					title, // 分享标题
					desc, // 分享描述
					link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: img, // 分享图标
					success: resolve,
					fail: reject
				});
				wx.onMenuShareAppMessage({
					title, // 分享标题
					desc, // 分享描述
					link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: img, // 分享图标
					success: resolve,
					fail: reject
				});
				                   wx.updateTimelineShareData({
				                       title, // 分享标题
				                       link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				                       imgUrl: img, // 分享图标
				                   });
				//                    /*分享到腾讯微博*/
				//                    wx.onMenuShareWeibo({
				//                        title, // 分享标题
				//                        desc, // 分享描述
				//                        link, // 分享链接
				//                        imgUrl: img, // 分享图标
				//                    });
			});
		});
	}
}
export default WxSDK;
