class EventEmitter {
    constructor() {
        this.events = {};
        this.val=[];
    }
    get value(){
        return this.val;
    }
    set value(val){
        this.val=val;
    }
    // 注册事件 可重复触发
    on(type, fn, context = undefined) {
        if (!this.events[type]) {
            this.events[type] = [];
        }
        this.events[type].push([fn, context]);
        return this;
    }
    // 注册事件 只能注册一次本类事件
    onSingle(type, fn, context = undefined) {
        if (!this.events[type]) {
            this.events[type] = [];
        }
        this.events[type][0]=[fn, context];
        return this;
    }
    // 注销事件
    off(type, fn) {
        if (!type && !fn) {
            this.destroy();
            return this;
        }
        if (!this.events[type]) {
            console.warn(`${type} is not in events`);
            return false;
        }
        if (!fn) {
            this.events[type] = [];
            return this;
        }
        this.events[type].forEach((event,index) => {
            if (event[0] === fn) this.events[type].splice(index,1);
        });
        return this;
    }
    // 注册只触发一次的事件
    once(type,fn,context=undefined){
        const onceFn=(...args)=>{
            this.off(type,fn);
            fn.call(context,...args);
        }
        this.on(type,onceFn,context);
    }
    // 触发事件
    emit(type,...args){
        if(!this.events[type]) return this;
        let result=[];
        this.events[type].forEach(event=>{
            result.push(event[0].call(event[1],...args));
        });
        this.value=result;
        return this;
    }
    destroy() {
        this.events = {};
        this.value=[];
        return this;
    }
}
export default EventEmitter;
