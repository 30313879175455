
// export default function registerComponent(app){
// 	const context=import.meta.globEager('./**/index.vue');
// 	Object.keys(context).forEach(fileName=>{
// 		const component=context[fileName],name=component.default.name;
// 		app.component(name,component.default||component);
// 	})
// }
export function registerDirective(app){
	const context=import.meta.globEager('./**/index.js');
	Object.keys(context).forEach(fileName=>{
		context[fileName].default(app);
	})
}