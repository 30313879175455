import purefn from '@widget/purefn';
import uface from '@widget/uface';
import wx from '@utils/wx';
import store from 'store';
import md5 from "@/plugins/md5";

import proConfig from '@env';
let timer;
// isAuthHandle = false;//是否处理errcode，防止多个接口都跳转登录
const handle = {
	before: http => http.interceptor.request.use(function(config) {
		config.header.token = store.state.token;
		// config.header.token = '23a3e61534cc0f1507be242edb07dee4';
		// #ifdef H5
		config.header.platform = 'wx';
		// #endif
		// #ifdef APP-PLUS
		config.header.platform = 'app';
		config.header['version-code'] = plus.runtime.versionCode;
		// #endif
		console.log('请求 start',config,store.state.loading)
		if (config.loading!==false && store.state.loading === 0) {
			uface.showLoading(config.loading||{});
			store.commit('increaseLoading');
		}
		// 加密账号密码
		 if(config.data) Object.keys(config.data).forEach((key) => {
		    if (typeof config.data[key] === "string" && key.toLowerCase().includes("password"))
		      config.data[key] = md5(config.data[key]);
		  });
		// 添加公共参数 caseid casedetailid
		// if(location.search){
		// 	const query=purefn.StrUtil.queryString(location.search);
		// 	if (query.caseid) {
		// 	      config.data.caseid = query.caseid;
		// 	      config.data.casedetailid = query.casedetailid;
		// 	    }
		// }

		return config;
	}, err => {
		return Promise.reject(err)
	}),
	after: http => http.interceptor.response.use(function({
		res,
		config
	}) {
		return new Promise(
			(resolve, reject) => {
				if (config.loading!==false && store.state.loading > 0) store.commit('subtractLoading');
				console.log('打印请求返回值',config.fullRequestUrl,res.data,res.data.data)
				try {
					const {
						check,
						data = {},
					} = res.data;
					if (timer) clearTimeout(timer);
					if (data &&data.hasOwnProperty&& data.hasOwnProperty('result') && data.hasOwnProperty('msg') && config
						.loading!==false) uface.hideLoading();
					else timer = setTimeout(() => {
						if (store.state.loading === 0) uface.hideLoading();
					}, 400);
					if (check&&check.token && store.state.token != check.token) store.commit('setToken', check.token);
					if (check) {
						const {
							code,
							hr_code
						} = check;
							if(hr_code) store.commit('setModule',hr_code)
						if (config.check && code) {
						if(process.env.NODE_ENV==='development') return
							setTimeout(() => {
								store.commit('setCache', {
									isAuthHandle: false
								})
							}, 3000)
							if (code == 1) {
								store.commit('setModule','')
								// 跳转绑定手机号
								uface.jump({
									mode: 'redirect',
									path: '/pages/bindPhone?userid='+(data&&data.userid)
								});
								return false;
							} else if (code == 2) {
								store.commit('setModule','')
								store.commit('setToken', '');
								const pages = getCurrentPages(),
									prevPage = pages[pages.length - 1] || null;
								// if(!store.state.entryPath)
								// store.commit('setEnteryPath', prevPage ? prevPage.route + '?' + purefn
								// 	.ObjUtil.queryString(prevPage.options||{}) : 'home');
								// #ifdef H5
								// wx.authorize();
								// #endif
								// #ifdef APP-PLUS
								// uface.jump({
								// 	mode: 'redirect',
								// 	path: '/pages/auth'
								// });
								// #endif
								return false;
							}else if(code==3){
								uface.jump({
									mode: 'redirect',
									path: '/pages/noAuth'
								});
							} 
						}
					}
					// 防止登录前token失效返回无数据导致报错
					// if (data === null || data === undefined) return;
					if (typeof data !== 'object') {
						resolve(data);
						return
					}
					// 处理富文本
					if (data && data.hasOwnProperty('content') && data.content && data.content.includes(
							'>')) {
								
								data.content = data.content.replace(
						/(<img[^>]*)src="([^>"]*)"([^>]*>)/g,
						function(match, $1, $2, $3) {
							// if(match.includes('style')) return
							if ($2.includes('http'))
								return `${$1} style="max-width:100%;" src="${$2}"${$3}`;
							return `${$1} style="max-width:100%;" src="${proConfig.backUrl}${$2}"${$3}`;
						})
						console.log(data.content)
						};
					if (data && config.toast && data.hasOwnProperty(
							'result') && data.hasOwnProperty('msg')) {
						// 表单提交
						uface.showToast({
							title: data.msg,
							icon: data.result ? 'success' : 'warning',
							duration:data.result ?2500:3000
						}).then(() => {
							resolve(data);
						});
					} else {
						resolve(data);
					}
				} catch (err) {
					uface.hideLoading();
					console.error(err);
					console.error('接口错误，请立刻更正!报错接口：' + config.url);
					uni.showModal({
						content: '服务器错误，请联系管理员!',
						showCancel: false
					});
					// reject('接口结构错误，请立刻更正!!报错接口：' + config.url);
				}
			});
	}, function(err) {
		uface.hideLoading();
		if (err.stack) console.error(err);
		else {
			const {
				err: errMsg,
				config,
				showModal
			} = err;
			if (showModal && errMsg) uface.showModal({
				content: errMsg,
				showCancel: false
			});
		}
		return Promise.reject(err);
	})
}



export {handle};