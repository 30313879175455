export default {
  // baseUrl: "http://hrapi.tyjfwy.com/public/index.php/wx",
  // txhgUrl: "http://txhgapi.tyjfwy.com/public/index.php/wx",
  // #ifdef H5
  // baseUrl: "https://officialapp.tyjfwy.com",
  baseUrl: "https://wxapp.tyjfwy.com",
  token: "8e6770219ebc1b7f",
  // token: "_dc50f66_5f756b4",
  // #endif
  // #ifdef APP-PLUS
  baseUrl: "https://officialapp.tyjfwy.com",
  token: "",
  // #endif
  domain: "https://officialapi.tyjfwy.com",
  imgURL:'http://officialapi.tyjfwy.com',
  currentModule:'',
  appid: "8e6770219ebc1b7f",
  backUrl: "http://officialapi.tyjfwy.com", //后台地址，用于展示图片
};