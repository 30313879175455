export default function (fn) {
    const active = {
        /** 
         * 转换boolean值
         * value mixed 要转换的值
         */
        isTrue(value) {
            if (typeof (value) == 'object') {
                if (value === null) {
                    return !!value;
                }
                if (value instanceof Array) {
                    return !!value.length;
                } else {
                    return !!Object.keys(value).length;
                }
            } else {
                return !!value;
            }
        },
        /**
         * @description 获取对象类型
         * @param {*} param 
         */
        getType(param) {
            // return Object.prototype.toString.call(param).slice(8,14).toLowerCase();
            return Object.prototype.toString.call(param).match(/^\[object\s(.*)\]$/)[1];
        },
        /**
         * 比较数据值是否相同  非引用地址比较
         * @param {*} value 
         * @param {*} diffValue 
         */
        isSame(value, diffValue) {
            if (typeof value !== typeof diffValue) return false;
            if (active.getType(value) === 'array') {
                if (value.length === diffValue.length) {
                    return value.reduce((accumulator, current, index) => active.isSame(current, diffValue[index]), false);
                }
                return false;
            } else if (active.getType(value) === 'object') {
                if (Object.keys(value) === Object.keys(diffValue)) {
                    return Object.keys.reduce((accumulator, current) => active.isSame(value[current], diffValue[current]), false)
                }
                return false;
            } else {
                return value === diffValue;
            }
        },
        isJSON(str) {
            if (typeof str == 'string') {
                try {
                    var obj = JSON.parse(str);
                    if (typeof obj == 'object' && obj) {
                        return true;
                    } else {
                        return false;
                    }

                } catch (e) {
                    console.log('error：' + str + '!!!' + e);
                    return false;
                }
            }
        }
    };
    return (...rest) => active[fn](...rest);
}