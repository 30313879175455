 /**
  * [showModal description] 模拟弹框
  * @method showModal
  * '@date 2018-08-13'
  * '@author zkq'
  * @param  {string}  title              [标题] 必填
  * @param  {string}  content            [内容] 必填
  * @param  {Boolean} [showCancel=false] [是否显示取消按钮]
  */
 function showModal({content, showCancel = true, title = '提示', cancelText = '取消', confirmText = '确定'}) {
     return new Promise(
         (resolve, reject) => {
             uni.showModal({
                 title,
                 content,
                 showCancel,
                 cancelText,
                 confirmText,
                 success: (res) => {
                     if (res.confirm) {
                         resolve(true);
                     } else if (res.cancel) {
                         resolve(false);
                     }
                 },
                 fail: (err) => {
                     reject(err);
                 }
             });
         }
     );
 }
 export default showModal;
