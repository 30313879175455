/** @internal */
export function isArray(obj) {
  return Array.isArray(obj);
}
/** @internal */
export function isString(obj) {
  return typeof obj === "string";
}
export function isNumber(obj) {
  return typeof obj === "number";
}
/** @internal */
export function isObject(obj) {
  return !!obj && typeof obj === "object" && !isArray(obj);
}
/** @internal */
export function isFunction(obj) {
  return typeof obj === "function";
}
/** @internal */
export function isPrimitive(arg) {
  const type = typeof arg;
  return arg !== undefined && type !== "object" && type !== "function";
}
/** @internal */
export function isObjectEmpty(obj) {
  return obj && typeof obj === "object" && Object.keys(obj).length === 0;
}
/** @internal */
export function isSymbol(obj) {
  return typeof obj === "symbol";
}
/** @internal */
export function isBoolean(obj) {
  return typeof obj === "boolean";
}
/** @internal */
export function isPromise(obj) {
  return obj instanceof Promise;
}
export function isEmpty(obj) {
  return obj && Object.keys(obj).length === 0;
}
const setPrimitives = new Set(["boolean", "string", "number"]);
/** @internal */
export function isActualPrimitive(arg) {
  return setPrimitives.has(typeof arg);
}
/** @internal */
export function isChildNodeValue(node) {
  return !!node.parent;
}
export function isInValid(val) {
  return val === undefined || val === null || Number.isNaN(val);
}
