/**
 *  continuation重写函数
 * 科里化函数, 管道流通时以`return`返回数据集
 * @param {*} functions  函数数组
 */
// const pipe = functions => data => functions.reduce(
    // (value, fn) => fn(value), data
// );

// 一行版，支持多参数，但必须至少传一个函数
const pipeMultiple= (...fns) => fns.reduce((acc, fn) => (...args) => fn(acc(...args)));


// 一行版，只支持单参数，但支持不传函数,科里化传参
const pipeSignal = (...fns) => arg => fns.reduce((acc, fn) => fn(acc), arg);

export {pipeSignal,pipeMultiple};