import deepClone from "./clone";
const hasOwnProperty=Object.prototype.hasOwnProperty;
/**
 * @description 合并对象深拷贝(限于两个对象)，且会改变第一个对象的值
 */
function deepCompound(target, source) {
    for (let key in source) { // 遍历目标
        if (hasOwnProperty.call(source, key)) {
            if (source[key] && typeof source[key] === 'object') {
                // 如果值是引用，则递归
                if (hasOwnProperty.call(target, key)) {
                    target[key] = deepCompound(target[key], source[key]);
                } else {
                    target[key] = deepClone(source[key]);
                }
            } else if(typeof source[key]!==undefined&&source[key]!==null) {
                // 如果不是，就直接赋值
                target[key] = source[key];
            }
        }
    }
    return target;
}
/**
 * @description 合并对象深拷贝，且会改变第一个对象的值
 * @param  {...any} rest 
 */
function merge(...rest) {
    return rest.reduce((accu, cur) => deepCompound(accu,cur));
}
export default merge;