/**
 * [parallel description] 并行
 * @method parallel
 * '@date 2018-08-13'
 * '@author zkq'
 * @param  {[type]} fnArray [description] 函数列表
 * @param  {[type]} success [description]
 * @param  {[type]} fail    [description]
 */
function parallel(fnArray, success, fail) {
    let array = fnArray.map(
        (fn) => {
            return new Promise(
                (resolve, reject) => {
                    fn(resolve, reject);
                }
            );
        }
    );
    Promise.all(array).then(
        value => {
            success(value);
        }
    ).catch(
        err => {
            fail(err);
        }
    );
}
export default parallel;