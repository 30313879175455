<template>
	<view class="img-wrapper" :style="{ width: W, height: H }">
		<image class="inner-img" :style="{ width: wh, height: hh }" v-show="!isLoad" mode="aspectFill" :src="dfSrc"></image>
		<image
			class="inner-img"
			:style="{ width: wh, height: hh }"
			v-show="isLoad"
			:lazy-load="true"
			:mode="mode"
			:src="cySrc"
			@load="load"
			@error="error"
			@click="previewImg"
		></image>
		<u-popup v-model="previewImage">
			<image
				class="inner-img"
				style="max-width: 90vw;"
				:mode="mode"
				:src="cySrc"
				@load="load"
				@error="error"
			></image>
		</u-popup>
	</view>
</template>

<script>
/**
 * 若width,height具体数值,则使用
 * 若width,height为100%，则wrapper赋值，img为100%
 */
import env from '@env';
import addUnit from '../../lib/function/addUnit.js';
const { backUrl,imgURL }=env;
export default {
	name: 'u-img',
	props: {
		mode: {
			type: String,
			default: 'aspectFill'
		},
		src: {
			type: String,
			default: ''
		},
		original: {
			type: Boolean,
			default: false
		},
		width: [Number, String],
		height: [Number, String],
		default: {
			type: String,
			default: '/static/images/none.png'
		},
		preview: {
			type: Boolean,
			default: false
		},
		previewList: {
			type: Array,
			default: () => []
		},
		previewPrefix: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			isLoad: false,
			isError: false,
			cySrc: '',
			dfSrc: this.default,
			backUrl,
			oW: '',
			oH: '',
			previewImage:false
		};
	},
	computed: {
		W() {
			const width = this.width;
			if (width) {
				if (typeof width === 'string' && width.includes('%')) return width;
				else return addUnit(width);
			} else return '';
		},
		H() {
			const size = this.height;
			if (size) {
				if (typeof size === 'string' && size.includes('%')) return size;
				else return addUnit(size);
			} else return '';
		},
		wh() {
			const width = this.width;
			if (width) {
				if (typeof width === 'string' && width.includes('%')) return '100%';
				else return addUnit(width);
			} else return this.original ? '' : '100%';
		},
		hh() {
			const size = this.height;
			if (size) {
				if (typeof size === 'string' && size.includes('%')) return '100%';
				else return addUnit(size);
			} else return this.original ? '' : '100%';
		}
	},
	watch: {
		src: {
			immediate: true,
			handler: function(src) {
				if (src) {
					if (src.startsWith('http')) this.cySrc = src;
					else if (src.startsWith('data:')) this.cySrc = src;
					else if(src.startsWith('blob:')) this.cySrc = src;
					else if(src.startsWith('/')) this.cySrc = src;
					else this.cySrc = `${imgURL}/${src}`;
					// this.isLoad = true;
					// console.log(this.cySrc)
				} else {
					this.cySrc = this.getDefault();
				}
				this.dfSrc = this.getDefault();
				const _this = this;
				if (src && src.startsWith('http'))
					uni.getImageInfo({
						src: _this.cySrc,
						success(res) {
							_this.oW = res.width + 'px';
							_this.oH = res.height + 'px';
						},
						fail(err) {
							// console.error(err)
						}
					});
			}
		}
	},
	methods: {
		load() {
			this.isLoad = true;
		},
		error(err) {
			// this.cySrc = this.dfSrc;
			this.isLoad = false;
		},
		getDefault() {
			if (this.default === 'user') return '/static/images/user-default.png';
			return this.default;
		},
		previewImg() {
			if(!this.preview) return false;
			// this.previewImage=true;
			const cySrc = this.cySrc;
			if (cySrc) {
				const previewList = this.previewList.map(item => this.previewPrefix + item);
				uni.previewImage({
					current: previewList.length <= 0 ? 0 : previewList.findIndex(item => item == cySrc),
					urls: previewList.length <= 0 ? [cySrc] : previewList
				});
			}
		},
		// 销毁代码
		beforeUnmount() {
			this.isLoad = false;
		}
	}
};
</script>

<style>
.img-wrapper {
	display: inline-block;
	overflow: hidden;
	max-width: 100%;
	max-height: 100%;
}

.inner-img {
	/* width: 100%; */
	/* height: 100%; */
}
</style>
