// 用于输入apis所需接口模块
import {
	handle
} from './tool.js'
import encrypt from './encrypt.js';
import proConfig from '@env';
import store from 'store';
import {
	purefn,
	Request
} from '@widget';
const http = new Request({
	baseUrl: proConfig.baseUrl,
	parseUrl: config => store.state.currentModule ?
		`${config.baseUrl}/${store.state.currentModule}api/${config.url}` : `${config.baseUrl}/api/${config.url}`
});
handle.before(http);
handle.after(http);
encrypt(http);
export default function simpleList(list) {
	if (typeof list === 'string') {
		return rest => http.post(
			purefn.ObjUtil.merge({
					toast: true,
					// loading: store.state.showLoading,
					check: true
				}, {
					url: list
				},
				rest
			)
		);
	}
	const res = {};
	for (let [key, value] of Object.entries(list)) {
		res[key] = function(rest) {
			const interceptorPrivate = http.getInterceptor(),
				defaultConf = {
					toast: true,
					// loading: store.state.showLoading,
					check: true
				};
			if (typeof value === 'string') {
				// 若属性值为string，则直接调用方法
				let [url, method] = value.split('|');
				if (!method) method = 'post';
				return http[method](purefn.ObjUtil.merge({
					url,
					header: {},
					data: {}
				}, defaultConf, rest));
			} else if (purefn.typeUtil('getType')(value) === 'Object') {
				const {
					url: urlRaw, //请求路径
					interceptor,
					config = {}
				} = value;
				let [url, method] = urlRaw.split('|');
				if (!method) method = 'post';
				if (interceptor && typeof interceptor === 'function') interceptor(interceptorPrivate);
				return http[method](purefn.ObjUtil.merge({
					url,
					header: {},
				}, defaultConf, config, rest), {
					interceptor: interceptorPrivate
				});
			}
		};
	}
	return res;
}
