import addUnit from '../../function/addUnit';
import {
	getFileFromBase64
} from '@utils/util';
// import * as faceapi from 'face-api.js';
const errorMap = {
 'NotAllowedError': '摄像头已被禁用，请在系统设置或者浏览器设置中开启后重试',
 'AbortError': '硬件问题，导致无法访问摄像头',
 'NotFoundError': '未检测到可用摄像头',
 'NotReadableError': '操作系统上某个硬件、浏览器或者网页层面发生错误，导致无法访问摄像头',
 'OverConstrainedError': '未检测到可用摄像头',
 'SecurityError': '摄像头已被禁用，请在系统设置或者浏览器设置中开启后重试',
 'TypeError': '类型错误，未检测到可用摄像头',
 'PermissionDeniedError':'摄像头授权失败，请授权后重试'
};
/** 微信黑屏 https://developers.weixin.qq.com/community/develop/article/doc/000c8e5f3a44f85c46fc1b5b251413
项目https://sdk.cloudroom.com/web/webrtc/meetingDemo/m_index.html
*/
class LiveVideo {
	constructor(config) {
		// this.init();
		this.create(config);
	}
	async init(){
		await faceapi.nets.ssdMobilenetv1.loadFromUri('/static/models');
		 // 指定面部检测器
		    this.faceOptions = new faceapi.SsdMobilenetv1Options({
		    // 最小置信阈值
		    // 默认值：0.5 
		      minConfidence: 0.9
		    })
		
	}
	create({
		width ,
		height ,
		videoEl
	} = {}) {
		this.config = {
			width:width||videoEl.clientWidth,
			height:height||videoEl.clientHeight,
			videoEl
		}
		console.log('config',this.config)
		this.createCanvas();
		// const video = this.video = document.createElement('video');
		// // video.src='https://img.cdn.aliyun.dcloud.net.cn/guide/uniapp/%E7%AC%AC1%E8%AE%B2%EF%BC%88uni-app%E4%BA%A7%E5%93%81%E4%BB%8B%E7%BB%8D%EF%BC%89-%20DCloud%E5%AE%98%E6%96%B9%E8%A7%86%E9%A2%91%E6%95%99%E7%A8%8B@20200317.mp4';
		// // video.style.display = 'none';
		// video.style.width = addUnit(width, 'px');
		// video.style.height = addUnit(height, 'px');
		// wrapper.appendChild(video);
		// video.play()
		// this.video=video;
		// this.detectFace()

	}
	createCanvas(){
		const config=this.config,video=config.videoEl;
		const wrapper = document.querySelector('#app')
		const canvas = document.createElement('canvas');
		canvas.width = config.width;
		canvas.height = config.height;
		this.canvas = canvas;
		this.canvasContext = canvas.getContext('2d');
		canvas.style.display = 'none';
		wrapper.appendChild(canvas)
	}
	static isSupport(){
		if((!navigator.mediaDevices ||!navigator.mediaDevices.getUserMedia)&&(!navigator.webkitGetUserMedia || !navigator.mozGetUserMedia ||
				!navigator.getUserMedia)) return false;
		return true;
	}
	getUserMedia() {
		if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices = {}
			navigator.mediaDevices.getUserMedia = (navigator.webkitGetUserMedia || navigator.mozGetUserMedia ||
				navigator.getUserMedia).bind(navigator);
		}
	}
	start() {
		if(this.stream){
			this.stream.getTracks()[0].stop();
			return
		}
		 this.getUserMedia();
		if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) throw new Error('getUserMedia is not implemented in this browser')
		const video = this.video;
		const constraints = {
			audio: false,
			video: {
				 facingMode: 'user',
				// width: this.config.width,
				// height: this.config.height,
				// transform: 'scaleX(-1)'
			}
		};
		return new Promise((resolve,reject) => {
			navigator.mediaDevices.getUserMedia(constraints)
				.then(stream => {
					const videoTracks = (this.stream = stream.getVideoTracks());
					console.log('Got stream with constraints:', constraints);
					console.log('Using video device: ' + videoTracks[0].label);
					stream.onended = function() {
						console.log('Stream ended');
					};
					this.stream = stream; // make variable available to browser console
					this.startVideo(this.config.videoEl,stream);
					resolve(stream)
				})
				.catch(function(error) {
					if (error.name === 'ConstraintNotSatisfiedError') {
						reject('The resolution ' + constraints.video.width.exact + 'x' + constraints
							.video.width
							.exact + ' px is not supported by your device.');
					} 
						// reject(
						// 	'Permissions have not been granted to use your camera and ' +
						// 	'microphone, you need to allow the page access to your devices in ' +
						// 	'order for the demo to work.'
						// );
					else if (errorMap[error.name]) {
					      reject(errorMap[error.name]);
					    }
					reject('getUserMedia error: ' + error.name, error);
				});

		})
	}
	startVideo(video, stream = this.stream) {
		this.video = video;
		video.srcObject = stream;
		video.onloadedmetadata = (e) =>{
			video.play();
		};
	}
	async detectFace(success){
		// await faceapi.nets.tinyFaceDetector.loadFromUri('/static/models');
		// await faceapi.nets.faceLandmark68Net.loadFromUri('/static/models');
		// await faceapi.nets.ssdMobilenetv1.loadFromUri('/static/models');
		console.log('ababab111')
		if(this.video.paused&&this.faceTimeout) return  clearTimeout(this.faceTimeout)
		const res= await faceapi.detectSingleFace(this.video,this.faceOptions);
		console.log('ababab11122',res)
		if(res) success(res)
		this.faceTimeout=setTimeout(()=>this.detectFace(success))
	}
	stop() {
		console.log(this.stream,this.stream.getVideoTracks,this.stream.active)
		// this.stream.getTracks()[0].stop();
	}
	snapshot() {
		const canvas = this.canvas,
			canvasContext = this.canvasContext;
		// 点击，canvas画图
		canvasContext.drawImage(this.video, 0, 0, this.config.width, this.config.height); // 获取图片base64链接
		const base64 = canvas.toDataURL('image/png');
		return {
			base64,
			file: getFileFromBase64(base64)
		};
	}
}
export default LiveVideo
