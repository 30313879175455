/**
 * [serial description] 串行
 * @method serial
 * '@date 2018-08-13'
 * '@author zkq'
 * @param  {[type]} fnArray [函数列表]
 */
function serial(fnArray, success, fail, data = 'serial init') {
    const fnEnd = fnArray.reduce(
        (prev, current) => {
            return prev.then(
                value => {
                    return new Promise(
                        (resolve, reject) => {
                            current(value, resolve, reject);
                        }
                    );
                }
            );
        }, Promise.resolve(data)
    );
    fnEnd.then(
        value => {
            success(value);
        }
    ).catch(
        err => {
            fail(err);
        }
    );
}
export default serial;