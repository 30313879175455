/**
 * [showToast description] 显示消息提示
 * @method showToast
 * '@date 2018-08-13'
 * '@author zkq'
 * @param  {[type]}  title           [description] 必填
 * @param  {[type]}  icon            [description] 必填
 * @param  {Number}  [duration=1400] [description]
 * @return {[type]}                  [description]
 */
function showToast({
	title = '成功',
	icon = 'none',
	duration = 1600
} = {}) {
	return new Promise(
		(resolve, reject) => {
			const images = {
				success: '/static/common/success-icon.png',
				error: '/static/common/error-toast-icon.png',
				warning: '/static/common/warning-toast-icon.png',
				tip: '/static/common/tip-toast-icon.png',
				default: ''
			};
			const image = images[icon] || images.default;
			// #ifdef APP-PLUS
			if (Object.keys(images).includes(icon)) {
				plus.nativeUI.toast(`<font style='font-size:16px;' color='#fff'>${title}</font>`, {
					background: 'rgba(17,17,17)',
					iconWidth: '38',
					iconHeight: '38',
					icon: image,
					duration: duration > 2000 ? 'long' : 'short',
					align: 'center',
					verticalAlign: 'center',
					type: 'richtext',
					richTextStyle: {
						color: '#fff'
					}
				});
				resolve();
			} else uni.showToast({	//hack 在app不同设备none显示效果不一致
				title,
				icon,
				mask: true,
				image,
				duration,
				success: (res) => {
					setTimeout(
						() => {
							resolve(res);
						}, duration
					);
				},
				fail: (err) => {
					reject(err);
				}
			});

			// #endif
			// #ifdef H5
			uni.showToast({
				title,
				icon,
				mask: true,
				image,
				duration,
				success: (res) => {
					setTimeout(
						() => {
							resolve(res);
						}, duration
					);
				},
				fail: (err) => {
					reject(err);
				}
			});
			// #endif
		}
	);
}
export default showToast;
