<template>
	<Teleport to="body">
		<view v-show="isShow">
			<u-mask v-if="showMask" :click-able='false' :z-index='zIndex' @close='close' v-model="isAnimateShow">
			</u-mask>
			<view :style="centerStyle">
				<view class="popup" :style="style"
					:class="['mode-'+modeInner,isAnimateShow?modeInner==='center'?'mode-center-in':'popup-show':modeInner==='center'?'mode-center-out':'']">
					<slot v-if="isShow"></slot>
				</view>
			</view>
		</view>
	</Teleport>
</template>
<script>
	import PopupManager from '../utils/popup-manage.js';
	/**
	 * popup 弹窗容器
	 * @description 弹出层容器，用于展示弹窗、信息提示等内容，支持上、下、左、右和中部弹出。组件只提供容器，内部内容由用户自定义
	 * @property {String} mode 弹出方向（默认left）
	 * @property {Boolean} mask 是否显示遮罩（默认true）
	 * * duration 过渡时间
	 * @property {Stringr | Number} length mode=left | 见官网说明（默认auto）
	 * @property {Boolean} zoom 是否开启缩放动画，只在mode为center时有效（默认true）
	 * @property {Boolean} safe-area-inset-bottom 是否开启底部安全区适配（默认false）
	 * @property {Boolean} mask-close-able 点击遮罩是否可以关闭弹出层（默认true）
	 * @property {Object} custom-style 用户自定义样式
	 * @property {Stringr | Number} negative-top 中部弹出时，往上偏移的值
	 * @property {Numberr | String} border-radius 弹窗圆角值（默认0）
	 * @property {Numberr | String} z-index 弹出内容的z-index值（默认1075）
	 * @property {Boolean} closeable 是否显示关闭图标（默认false）
	 * @property {String} close-icon 关闭图标的名称，只能uView的内置图标
	 * @property {String} close-icon-pos 自定义关闭图标位置（默认top-right）
	 * @property {String} close-icon-color 关闭图标的颜色（默认#909399）
	 * @property {Number | String} close-icon-size 关闭图标的大小，单位rpx（默认30）
	 * @event {Function} open 弹出层打开
	 * @event {Function} close 弹出层收起
	 * @example <u-popup v-model="show"><view>出淤泥而不染，濯清涟而不妖</view></u-popup>
	 */
	export default {
		name: 'u-popup',
		inheritAttrs: false,
		emits: ['update:modelValue'],
		props: {
			mode: {
				type: String,
				default: 'center'
			},
			duration: {
				type: [Number, String],
				default: 300
			},
			modelValue: {
				type: Boolean,
				default: false
			},
			showMask: {
				type: Boolean,
				default: true
			}
		},
		watch: {
			modelValue(val) {
				if (val) this.togger(val);
				else {
					if (this.closeFromInner) this.closeFromInner = false;
					else this.togger(val);
				}
			},
			mode: {
				handler(val) {
					if (val.includes('center')) this.modeInner = 'center';
					else this.modeInner = val;
				},
				immediate: true
			}
		},
		data() {
			return {
				zIndex: PopupManager.nextZIndex(),
				isShow: false,
				isAnimateShow: false,
				modeInner: '',
				closeFromInner: false, // value的值改变，是发生在内部还是外部
			};
		},
		computed: {
			style() {
				let style = {};
				if (this.mode === 'left' || this.mode === 'right') {
					style = {
						height: '100%',
						transform: `translate3D(${this.mode==='left'?'-100%':'100%'},0,0)`
					};
				} else if (this.mode === 'top' || this.mode === 'bottom') {
					style = {
						width: '100%',
						transform: `translate3D(0,${this.mode==='top'?'-100%':'100%'},0)`
					};
				} else if (this.mode.includes('center')) {
					style = {};
				}
				if (!this.mode.includes('center')) {
					style.transition = `all ${this.duration/1000}s linear`;
					style.position = 'fixed';
					style['z-index'] = this.zIndex + 1;
				}
				return style;
			},
			centerStyle() {
				if (this.mode.includes('center')) {
					let style = {};
					const top = this.mode.split('-')[1];
					style.position = 'fixed';
					style['z-index'] = this.zIndex + 1;
					style.left = '50%';
					if (top) {
						style.top = top;
						style.transform = 'translateX(-50%)';
					} else {
						style.top = '50%';
						style.transform = 'translateX(-50%) translateY(-50%)';
					}
					return style;
				}
				return {}
			}
		},
		methods: {
			empty() {},
			// 标记关闭是内部发生的，否则修改了value值，导致watch中对value检测，导致再执行一遍close
			// 造成@close事件触发两次
			close() {
				this.closeFromInner = true;
				this.togger(false)
			},
			/**
             * 动画进入原理
             * 先显示整个组件，一段时间后再让动画显示, 否则打开后组件会直接到达目的位置
             * 退出先动画退出，在关闭组件
			 * // 此处的原理是，关闭时先通过动画隐藏弹窗和遮罩，再移除整个组件
		// 打开时，先渲染组件，延时一定时间再让遮罩和弹窗的动画起作用
             */
			togger(isShow) {
				if (isShow) {
					this.isShow = isShow;
					setTimeout(() => {
						this.isAnimateShow = isShow;
					}, 30);
				} else {

					this.isAnimateShow = isShow;
					setTimeout(() => {
						this.isShow = isShow;
					}, this.duration);

					this.$emit('update:modelValue', false);
				}
			}
		}
	}
</script>
<style lang="less" scoped>
	/* 弹入 */
	@keyframes bouncein {
		0% {
			opacity: 0;
			transform: scale(0.3);
		}

		// 50% {
		//     opacity: 1;
		//     transform: scale(1.05);
		// }

		70% {
			transform: scale(0.9);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes bounceout {

		0% {
			transform: scale(1);
		}

		20% {
			opacity: 0.8;
			transform: scale(0.4);
		}

		// 70% {
		// 	opacity: 0.2;
		// 	transform: scale(0.2);
		// }

		100% {
			opacity: 0;
			transform: scale(0);
		}

	}

	.popup {
		overflow: hidden;


		&.mode-left {
			left: 0;
			top: 0;
			bottom: 0;
		}

		&.mode-right {
			top: 0;
			bottom: 0;
			right: 0;
		}

		&.mode-top {
			top: 0;
			left: 0;
			right: 0;
		}

		&.mode-bottom {
			bottom: 0;
			left: 0;
			right: 0;
		}

		&.mode-center-in {
			animation: bouncein .4s;
		}

		&.mode-center-out {
			animation: bounceout .4s;
			animation-fill-mode: forwards;
		}

		&-show {
			transform: translate3d(0, 0, 0) !important;
		}
	}
</style>
